import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Navigation from './Components/Navigation';
import SubscriptionPage from './Components/SubscriptionPage';

function App() {

const { loginWithRedirect, user, isLoading, isAuthenticated, logout } = useAuth0();

useEffect(()=>{    
  if(!user && !isLoading){
    setCurrentDateTimeToSessionStorage();
    loginWithRedirect()
  }else if(user && !isLoading){
    const hasFifteenMinutesPassed = isDifferenceGreaterOrEqualFifteenMinutes();
    if (hasFifteenMinutesPassed) {
      logout()
    } 
  }
},[isLoading])


function setCurrentDateTimeToSessionStorage() {
  const currentDateTime = new Date().toISOString(); // Store as an ISO string
  window.sessionStorage.setItem('dateTime', currentDateTime);
}

function isDifferenceGreaterOrEqualFifteenMinutes() {
  const storedDateTimeStr = window.sessionStorage.getItem('dateTime');
  
  if (!storedDateTimeStr || !(new Date(storedDateTimeStr)).valueOf() ) {
    // If there's no stored date time, return false or handle accordingly
    return false;
  }

  const storedDateTime = new Date(storedDateTimeStr);
  const currentDateTime = new Date();

  const differenceInMilliseconds = currentDateTime - storedDateTime;
  const fifteenMinutesInMilliseconds = 15 * 60 * 1000; // 15 minutes converted to milliseconds

  return differenceInMilliseconds >= fifteenMinutesInMilliseconds;
}

const LoginButton = () => {

  return <button className='p-10 bg-white rounded-full w-60 text-xl' onClick={() => loginWithRedirect()}>Log In</button>;
};

  return (      
    <>
      <div className='w-full flex justify-center'>
        <SubscriptionPage />            
      </div>      
    </>
  );
}

export const PRODUCTION_ENDPOINT = 'https://injusticepa-restapi-e3e218589278.herokuapp.com' //move to its own file later
export default App;