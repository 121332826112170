import React, { useState } from 'react';
import { FaCheck, FaLock } from 'react-icons/fa';
import './SubscriptionPage.css';
import { useAuth0 } from '@auth0/auth0-react';
const SubscriptionPage = () => {
  const [selectedTier, setSelectedTier] = useState(0);

  // Sample tier data
  // const tiers = ['Free', 'Single Plan'];
  const tiers = ['Single Plan'];
  // const tierColors = ['#ADD8E6', 'gold'];
  const tierColors = ['gold'];
  // const tierPrices = ["$0", '$27'];
  const tierPrices = ['$27'];
  const [billingCycle, setBillingCycle] = useState(0); 
  const {user, isLoading, logout } = useAuth0()
  const features = [
    // {
    //   tier: 'Plus',
    //   details: {
    //     'Protection': [
    //       { name: 'Connections', description: '3 per month', isAvailable: true },
    //       { name: 'No Fees', description: 'No consulting fees', isAvailable: true },
    //       { name: 'Representation', description: 'Keep same legal representative', isAvailable: false }
    //     ],
    //     'Access': [
    //       { name: 'Cloud Video Storage', description: '30 days cloud storage', isAvailable: true},
    //       { name: 'Video Exports', description: '3 per month', isAvailable: true},
    //       { name: 'Priority Support', description: '', isAvailable: false}
    //     ]
    //   }
    // },
    {
      tier: 'Single Plan',
      details: {
        'Protection': [
          { name: 'Connections', description: 'Unlimited', isAvailable: true },
          { name: 'No Fees', description: 'No consulting fees', isAvailable: true },
          { name: 'Representation', description: 'Keep same legal representative', isAvailable: true }
        ],
        'Access': [
          { name: 'Cloud Video Storage', description: '90 day cloud storage', isAvailable: true },
          { name: 'Video Exports', description: 'Unlimited', isAvailable: true},
          { name: 'Priority Support', description: '', isAvailable: true }
        ]
      }
    }
];


  const Feature = ({ name, description, isAvailable }) => (
    <div className="featureContainer">
      <div className="iconContainer">
        {isAvailable ? <FaCheck color="#007AFF" /> : <FaLock color="grey" />}
      </div>      
        <div className="featureTextContainer">
            <div className="featureName">{name}</div>
            <div className="featureDescription">{description}</div>
        </div>
    </div>
  );

  return (
    <div className="container">
      <div className="swiperContainer">
        {tiers.map((tier, index) => (
          <div
            key={index}
            className="slide"
            style={{ background: `linear-gradient(white, ${tierColors[index]})` }}
            onClick={() => setSelectedTier(index)}
          >
            <span className="tierText">{tier}</span>
          </div>
        ))}
      </div>
      <div className="actionsContainer">
        <div className="billingToggle">
            <span className={billingCycle === 0 ? 'active' : ''} onClick={() => setBillingCycle(0)}>Monthly</span>
            <span className={billingCycle === 1 ? 'active' : ''} onClick={() => setBillingCycle(1)}>Yearly</span>
        </div>
        <button className="logoutButton" onClick={() => logout({ returnTo: window.location.origin })}>Logout</button>
      </div>

      <div className="features">
          {Object.entries(features[selectedTier].details).map(([category, categoryDetails], index) => (
              <div key={index}>
                  <div className="categoryTitle">{category}</div>
                  {categoryDetails.map((featureDetail, idx) => (
                      <Feature
                          key={idx}
                          name={featureDetail.name}
                          description={featureDetail.description}
                          isAvailable={featureDetail.isAvailable}
                      />
                  ))}
              </div>
          ))}
      </div>

        {(user && Number.parseInt(user.app_metadata['tier']) >=1) ? 
          <>
          <button 
          className="purchaseButton" style={{ background: `linear-gradient(${tierColors[selectedTier]}, white)` }}
          onClick={()=>{window.open("https://injusticepa.com","_self")}}
          >
            Continue
          </button>
          <p className='text-white text-xl'>You are already on the Single Plan!</p>     
          </>
          : 
          <button 
          className="purchaseButton" style={{ background: `linear-gradient(${tierColors[selectedTier]}, white)` }}
          onClick={()=>{
            if(billingCycle === 0 && !isLoading){
              console.log(user.app_metadata['single_plan_monthly_url'])
              window.open(user.app_metadata['single_plan_monthly_url'], "_self")
            }
            else if(billingCycle === 1 && !isLoading){
              console.log(user.app_metadata['single_plan_yearly_url'])
              window.open(user.app_metadata['single_plan_yearly_url'], "_self")
            }
  
          }}
          
          >
          {!isLoading ? `BUY NOW (${billingCycle === 0 ? 27 : 265})` : "Loading..."}
          {/* BUY NOW ({tierPrices[selectedTier]}) */}
        </button>
        }
    </div>
  );
};

export default SubscriptionPage;
